import Vue from "vue";
import VueRouter from "vue-router";

import axios from "axios";
import Swal from "sweetalert2";

Vue.use(VueRouter);

import Forbidden from "../pages/Forbidden.vue";
import Dashboard from "../pages/Dashboard.vue";
import Login from "../pages/Login.vue";
import Registrasi from "../pages/Registrasi.vue";

import JenisKegiatanList from "../pages/master/jenis-kegiatan/List.vue";
import RekeningKoranList from "../pages/master/rekening-koran/List.vue";
import RekeningKoranImport from "../pages/master/rekening-koran/Import.vue";
import PotensialList from "../pages/master/potential/List.vue";
import InstansiList from "../pages/master/instansi/List.vue";

import CustomersList from "../pages/customers/List.vue";
import CustomersForm from "../pages/customers/Form.vue";
import CustomersUpdate from "../pages/customers/Update.vue";
import CustomersDetail from "../pages/customers/Detail.vue";
import CustomersImport from "../pages/customers/Import.vue";

import TransactionsList from "../pages/transactions/List.vue";
import TransactionsForm from "../pages/transactions/Form.vue";
import TransactionsBulk from "../pages/transactions/Bulk.vue";
import TransactionsDetail from "../pages/transactions/Detail.vue";
import TransactionsPrint from "../pages/transactions/Print.vue";
import TransactionsPrintSkrd from "../pages/transactions/Skrd.vue";
import TransactionsPrintSsrd from "../pages/transactions/Ssrd.vue";

import Profil from "../pages/profil/Profil.vue";

import Pengguna from "../pages/users/List.vue";
import PenggunaForm from "../pages/users/Form.vue";
import PenggunaEdit from "../pages/users/Edit.vue";
import PenggunaPassword from "../pages/users/Password.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard/",
  },
  { path: "*", redirect: "/dashboard/" },
  { path: "/forbidden", component: Forbidden },
  { path: "/dashboard", component: Dashboard },
  { path: "/login", component: Login },
  { path: "/registrasi", component: Registrasi },
  { path: "/pengguna", component: Pengguna, meta: { requiredLevel: "admin" } },
  {
    path: "/pengguna/update/:id",
    component: PenggunaEdit,
    meta: { requiredLevel: "admin" },
  },
  {
    path: "/pengguna/password/:id",
    component: PenggunaPassword,
    meta: { requiredLevel: "admin" },
  },
  {
    path: "/pengguna/create",
    component: PenggunaForm,
    meta: { requiredLevel: "admin" },
  },

  {
    path: "/data-pelanggan",
    component: CustomersList,
    meta: { requiredLevel: ["admin", "loket", "kelurahan", "penagih"] },
  },
  {
    path: "/data-pelanggan/import",
    component: CustomersImport,
    meta: { requiredLevel: ["admin", "loket", "kelurahan", "penagih"] },
  },
  {
    path: "/data-pelanggan/create",
    component: CustomersForm,
    meta: { requiredLevel: ["admin", "loket", "kelurahan", "penagih"] },
  },
  {
    path: "/data-pelanggan/update/:id",
    component: CustomersUpdate,
    meta: { requiredLevel: ["admin", "loket", "kelurahan", "penagih"] },
  },
  {
    path: "/data-pelanggan/detail/:id",
    component: CustomersDetail,
    meta: { requiredLevel: ["admin", "loket", "kelurahan", "penagih"] },
  },

  {
    path: "/data-transaksi",
    component: TransactionsList,
    meta: { requiredLevel: ["admin", "loket", "kelurahan"] },
  },
  {
    path: "/data-transaksi/create",
    component: TransactionsForm,
    meta: { requiredLevel: ["admin", "loket", "kelurahan"] },
  },
  {
    path: "/data-transaksi/bulk",
    component: TransactionsBulk,
    meta: { requiredLevel: ["admin", "loket", "kelurahan"] },
  },
  {
    path: "/data-transaksi/detail/:id",
    component: TransactionsDetail,
    meta: { requiredLevel: ["admin", "loket", "kelurahan"] },
  },
  {
    path: "/data-transaksi/print/:id",
    component: TransactionsPrint,
    meta: { requiredLevel: ["admin", "loket", "kelurahan"] },
  },
  {
    path: "/data-transaksi/print/:id",
    component: TransactionsPrint,
    meta: { requiredLevel: ["admin", "loket", "kelurahan"] },
  },
  {
    path: "/data-transaksi/skrd/:id",
    component: TransactionsPrintSkrd,
    meta: { requiredLevel: ["admin", "loket", "kelurahan"] },
  },
  {
    path: "/data-transaksi/ssrd/:id",
    component: TransactionsPrintSsrd,
    meta: { requiredLevel: ["admin", "loket", "kelurahan"] },
  },

  {
    path: "/master/jenis-kegiatan/",
    component: JenisKegiatanList,
    meta: { requiredLevel: "admin" },
  },
  {
    path: "/master/rekening-koran/",
    component: RekeningKoranList,
    meta: { requiredLevel: "admin" },
  },
  {
    path: "/master/rekening-koran/import",
    component: RekeningKoranImport,
    meta: { requiredLevel: "admin" },
  },
  {
    path: "/master/data-potensial/",
    component: PotensialList,
    meta: { requiredLevel: "admin" },
  },
  {
    path: "/master/data-potensial/import",
    component: CustomersImport,
    meta: { requiredLevel: "admin" },
  },
  {
    path: "/master/instansi",
    component: InstansiList,
    meta: { requiredLevel: "admin" },
  },

  { path: "/profil", component: Profil },
  { path: "/profil/:page", component: Profil },
];

const router = new VueRouter({
  mode: "history",
  // base: "/eregister/",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("sipData");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (to.path !== "/login" && !isLoggedIn && to.path !== "/registrasi") {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden"); // Tambahkan halaman forbidden jika level pengguna tidak sesuai
    } else {
      next();
    }
  } else {
    next();
  }

  const sipData = localStorage.getItem("sipData");
  if (sipData) {
    const user = JSON.parse(sipData);
    const appVersion = process.env.VUE_APP_VERSI;
    axios
      .get("/v1/version/", {
        headers: {
          token: user.token,
          versi: appVersion,
        },
      })
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            title: "Info",
            text: "Versi Baru Tersedia, Refresh Halaman",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
          });
          setTimeout(() => {
            Swal.close();
            window.location.reload();
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/v1/auth/status/", {
        headers: {
          token: user.token,
        },
      })
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            title: "Info",
            text: "Session Habis, Silahkan Login Kembali",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
          });
          setTimeout(() => {
            Swal.close();
            localStorage.removeItem("sipData");
            if (to.path !== "/login") {
              next("/login");
            }
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

export default router;
