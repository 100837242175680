<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">List Potensial</h3>
        </div>
        <div class="panel-body p-5">
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :search-options="{
              enabled: true,
              trigger: 'keyup',
              placeholder: 'Pencarian',
            }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-default mr-2"
                >
                  <i
                    class="fa fa-sync"
                    :class="{ 'fa-spin': this.isLoading }"
                  ></i>
                </button>
                <router-link
                  to="/master/data-potensial/import"
                  class="btn btn-success mr-2"
                >
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Import Data</span>
                </router-link>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div>
                  <button
                    class="btn btn-circle btn-icon btn-danger"
                    v-on:click="confirmDelete(props.row.customer_id)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../../plugins/sipData";
import moment from "moment";

export default {
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "Nama",
          field: "customer_name",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-wrap",
        },
        {
          label: "Alamat",
          field: "customer_address",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-wrap",
        },
        {
          label: "Kecamatan",
          field: "district_name",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap text-uppercase",
        },
        {
          label: "Kelurahan",
          field: "village_name",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap text-uppercase",
        },
        {
          label: "RW",
          field: "hamlet",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap text-uppercase",
        },
        {
          label: "RT",
          field: "neighbourhood",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap text-uppercase",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      searchTerm: "",
      timeoutSearch: null,
      loadDPT: false,
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    onSearch(params) {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(() => {
        this.searchTerm = params.searchTerm;
        this.fetchData();
      }, 500);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchData();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.fetchData();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.fetchData();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.fetchData();
    },

    fetchData() {
      this.isLoading = true;
      axios
        .get("/v1/old_customers/", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.total;
          if (this.totalRecords <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/old_customers/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Gagal",
              text: error.response.data.message,
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.fetchData();
            });
            console.log(error.response.data.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    currencyFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>