var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-3"},[(_vm.showPotensial)?_c('button',{staticClass:"btn btn-info",on:{"click":function($event){_vm.showPotensial = false}}},[_vm._v(" Tutup Data Potensial ")]):_c('button',{staticClass:"btn btn-info",on:{"click":function($event){_vm.showPotensial = true}}},[_vm._v(" Buka Data Potensial ")])]),(_vm.showPotensial)?_c('div',{staticClass:"col-md-12"},[_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"search-options":{
                  enabled: true,
                  trigger: 'keyup',
                  placeholder: 'Pencarian',
                },"pagination-options":{
                  enabled: true,
                  perPageDropdownEnabled: false,
                  perPageDropdown: [10, 20, 30, 40, 50, 100],
                  dropdownAllowAll: false,
                  position: 'bottom',
                  rowsPerPageLabel: '',
                  nextLabel: '',
                  prevLabel: '',
                  ofLabel: 'of',
                  pageLabel: 'page',
                  allLabel: '',
                },"rows":_vm.rows,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.onSearch,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('div',[_c('button',{staticClass:"btn btn-sm btn-block btn-primary",on:{"click":function($event){return _vm.fetchCostumers(props.row.customer_id)}}},[_vm._v(" Pilih ")])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,1440953808)},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.tableInfo)+" ")])])],1):_vm._e(),_vm._m(1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6",class:{
                'col-md-9':
                  _vm.form.identity_status == '3' || _vm.form.identity_status == '',
              }},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"customer_name"}},[_vm._v("Nama: ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.customer_name),expression:"form.customer_name"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.customer_name != '',
                    'is-invalid':
                      _vm.formValidate.customer_name || _vm.form.customer_name == '',
                  },attrs:{"type":"text","placeholder":"Nama","required":""},domProps:{"value":(_vm.form.customer_name)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "customer_name", $event.target.value)},function($event){_vm.formValidate.customer_name = ''}]}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"identity_status"}},[_vm._v("Jenis Identitas : ")]),_c('b-input-group',[_c('b-form-select',{class:{
                      'is-valid': _vm.form.identity_status != '',
                      'is-invalid':
                        _vm.formValidate.identity_status ||
                        _vm.form.identity_status == '',
                    },attrs:{"name":"identity_status","id":"identity_status"},on:{"change":function($event){_vm.formValidate.identity_status = ''}},model:{value:(_vm.form.identity_status),callback:function ($$v) {_vm.$set(_vm.form, "identity_status", $$v)},expression:"form.identity_status"}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Jenis Identitas")]),_c('option',{attrs:{"value":"1"}},[_vm._v("NIK (Nomor Induk Kependudukan)")]),_c('option',{attrs:{"value":"2"}},[_vm._v("NIB (Nomor Induk Berusaha)")]),_c('option',{attrs:{"value":"3"}},[_vm._v("Lainnya")])]),_c('b-input-group-append',[_c('b-tooltip',{attrs:{"target":"tooltip-jenis","triggers":"hover focus","placement":"top"}},[_c('ul',{staticStyle:{"text-align":"left","margin":"0","padding":"5px 5px 5px 15px"}},[_c('li',[_vm._v("NIK Khusus untuk perorangan")]),_c('li',[_vm._v("NIB Khusus untuk usaha yang memiliki NIB")])])]),_c('b-input-group-text',{attrs:{"id":"tooltip-jenis"}},[_c('i',{staticClass:"fa fa-info-circle",attrs:{"aria-hidden":"true"}})])],1)],1)],1)]),(
                _vm.form.identity_status == '1' || _vm.form.identity_status == '2'
              )?_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"identity_number"}},[_vm._v(" "+_vm._s(_vm.form.identity_status == "1" ? "NIK :" : "NIB :")+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.identity_number),expression:"form.identity_number"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.identity_number != '',
                    'is-invalid':
                      _vm.formValidate.identity_number ||
                      _vm.form.identity_number == '',
                  },attrs:{"type":"number","placeholder":_vm.form.identity_status === '1' ? 'NIK' : 'NIB',"required":""},domProps:{"value":(_vm.form.identity_number)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "identity_number", $event.target.value)},function($event){return _vm.validasiNik()}]}}),_c('small',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.identity_number)}})])]):_vm._e(),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"customer_address"}},[_vm._v("Alamat: ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.customer_address),expression:"form.customer_address"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.customer_address != '',
                    'is-invalid':
                      _vm.formValidate.customer_address ||
                      _vm.form.customer_address == '',
                  },attrs:{"name":"customer_address","id":"customer_address","placeholder":"Alamat","rows":"2","required":""},domProps:{"value":(_vm.form.customer_address)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "customer_address", $event.target.value)},function($event){_vm.formValidate.customer_address = ''}]}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"district"}},[_vm._v("Kecamatan : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.district),expression:"form.district"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.district != '',
                    'is-invalid':
                      _vm.formValidate.district || _vm.form.district == '',
                  },attrs:{"name":"district","id":"district"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "district", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.fetchVillages($event)},function($event){_vm.formValidate.district = ''}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.districtList),function(districts){return _c('option',{key:districts.id_kec,domProps:{"value":districts.id_kec}},[_vm._v(" "+_vm._s(districts.nama_kecamatan)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"village"}},[_vm._v("Kelurahan : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.village),expression:"form.village"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.village != '',
                    'is-invalid': _vm.formValidate.village || _vm.form.village == '',
                  },attrs:{"name":"village","id":"village"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "village", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.formValidate.village = ''}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.villageList),function(villages){return _c('option',{key:villages.id_kel,domProps:{"value":villages.id_kel}},[_vm._v(" "+_vm._s(villages.nama_kelurahan)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"hamlet"}},[_vm._v("RW : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hamlet),expression:"form.hamlet"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.hamlet != '',
                    'is-invalid': _vm.formValidate.hamlet || _vm.form.hamlet == '',
                  },attrs:{"type":"number","name":"hamlet","placeholder":"Contoh : 001"},domProps:{"value":(_vm.form.hamlet)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "hamlet", $event.target.value)},function($event){_vm.formValidate.hamlet = ''}]}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"neighbourhood"}},[_vm._v("RT : ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.neighbourhood),expression:"form.neighbourhood"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.neighbourhood != '',
                    'is-invalid':
                      _vm.formValidate.neighbourhood || _vm.form.neighbourhood == '',
                  },attrs:{"type":"number","name":"neighbourhood","placeholder":"Contoh : 001"},domProps:{"value":(_vm.form.neighbourhood)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "neighbourhood", $event.target.value)},function($event){_vm.formValidate.neighbourhood = ''}]}})])]),(_vm.form.identity_status == '1')?_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"employment_status"}},[_vm._v("Status ASN/Sipil : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.employment_status),expression:"form.employment_status"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.employment_status != '',
                    'is-invalid':
                      _vm.formValidate.employment_status ||
                      _vm.form.employment_status == '',
                  },attrs:{"name":"employment_status","id":"employment_status"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "employment_status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.formValidate.employment_status = '';
                    _vm.form.employment_status === '1'
                      ? (_vm.form.customer_categories =
                          '6fece19d-7f0d-4ab9-ab91-53f207fe979b')
                      : (_vm.form.customer_categories = '');}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_c('option',{attrs:{"value":"1"}},[_vm._v("ASN Kota Palu")]),_c('option',{attrs:{"value":"2"}},[_vm._v("ASN Non Kota Palu")]),_c('option',{attrs:{"value":"3"}},[_vm._v("Sipil")])])])]):_vm._e(),(_vm.form.employment_status == '1')?_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"institutions"}},[_vm._v("Instansi : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.institutions),expression:"form.institutions"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.institutions != '',
                    'is-invalid':
                      _vm.formValidate.institutions || _vm.form.institutions == '',
                  },attrs:{"name":"institutions","required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "institutions", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.formValidate.institutions = ''}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Instansi")]),_vm._l((_vm.instansiList),function(instansi){return _c('option',{key:instansi.government_agency_id,domProps:{"value":instansi.government_agency_id}},[_vm._v(" "+_vm._s(instansi.government_agency_name)+" ")])})],2)])]):_vm._e(),_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"customer_categories"}},[_vm._v("Jenis Kegiatan : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.customer_categories),expression:"form.customer_categories"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.customer_categories != '',
                    'is-invalid':
                      _vm.formValidate.customer_categories ||
                      _vm.form.customer_categories == '',
                  },attrs:{"name":"customer_categories","required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "customer_categories", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.formValidate.customer_categories = ''}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Jenis Kegiatan")]),_vm._l((_vm.categoiresList),function(categories){return _c('option',{key:categories.customer_category_id,domProps:{"value":categories.customer_category_id}},[_vm._v(" "+_vm._s(categories.category_name)+" ")])})],2)])])])]),_c('div',{staticClass:"panel-footer"},[_c('button',{staticClass:"btn btn-success pull-right",attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.postData}},[(_vm.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" Submit ")]),_c('a',{staticClass:"btn btn-default",attrs:{"href":"javascript:window.history.go(-1);"}},[_vm._v("Kembali")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-dark text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Form Pelanggan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('hr')])
}]

export { render, staticRenderFns }