<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">Rekening Koran</h3>
        </div>
        <div class="panel-body p-5">
          <vue-good-table
            :search-options="{
              enabled: true,
              placeholder: 'Pencarian',
            }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-default mr-2"
                >
                  <i
                    class="fa fa-sync"
                    :class="{ 'fa-spin': this.isLoading }"
                  ></i>
                </button>
                <router-link
                  to="/master/rekening-koran/import"
                  class="btn btn-primary mr-2"
                >
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Import Data</span>
                </router-link>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div>
                  <button
                    class="btn btn-danger btn-circle btn-icon"
                    v-on:click="confirmDelete(props.row.checking_account_id)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../../plugins/sipData";

export default {
  name: "DataList",
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "Kode",
          field: "transaction_code",
          sortable: false,
          width: "150px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Posting Date",
          field: "posting_date",
          sortable: false,
          width: "200px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Remark",
          field: "remark",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-wrap",
        },
        {
          label: "Credit",
          field: "credit",
          sortable: false,
          width: "150px",
          formatFn: this.currencyFormat,
          thClass: "text-center text-nowrap",
          tdClass: "text-right text-nowrap",
        },
        {
          label: "Record",
          field: "record",
          sortable: false,
          width: "150px",
          formatFn: this.currencyFormat,
          thClass: "text-center text-nowrap",
          tdClass: "text-right text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass: "text-center text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      form: {
        customer_category_id: "",
        year_period: "",
        category: "",
        category_name: "",
        cost: "",
      },
      modalVisible: false,
      conditionMet: false,
      formValidate: [],
      categoriesList: [],
      link: "/v1/customer_categories/create/",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      axios
        .get("/v1/checking_account/", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          if (this.rows.length <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/checking_account/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    currencyFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>