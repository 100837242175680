<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Pelanggan</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div
                class="col-md-6"
                :class="{
                  'col-md-9':
                    form.identity_status == '3' || form.identity_status == '',
                }"
              >
                <div class="form-group">
                  <label for="customer_name">Nama: </label>
                  <input
                    v-model="form.customer_name"
                    type="text"
                    class="form-control"
                    placeholder="Nama"
                    required
                    @input="formValidate.customer_name = ''"
                    :class="{
                      'is-valid': form.customer_name != '',
                      'is-invalid':
                        formValidate.customer_name || form.customer_name == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="identity_status">Jenis Identitas : </label>
                  <b-input-group>
                    <b-form-select
                      v-model="form.identity_status"
                      name="identity_status"
                      id="identity_status"
                      @change="formValidate.identity_status = ''"
                      :class="{
                        'is-valid': form.identity_status != '',
                        'is-invalid':
                          formValidate.identity_status ||
                          form.identity_status == '',
                      }"
                    >
                      <option value="">Pilih Jenis Identitas</option>
                      <option value="1">NIK (Nomor Induk Kependudukan)</option>
                      <option value="2">NIB (Nomor Induk Berusaha)</option>
                      <option value="3">Lainnya</option>
                    </b-form-select>
                    <b-input-group-append>
                      <b-tooltip
                        target="tooltip-jenis"
                        triggers="hover focus"
                        placement="top"
                      >
                        <ul
                          style="
                            text-align: left;
                            margin: 0;
                            padding: 5px 5px 5px 15px;
                          "
                        >
                          <li>NIK Khusus untuk perorangan</li>
                          <li>NIB Khusus untuk usaha yang memiliki NIB</li>
                        </ul>
                      </b-tooltip>
                      <b-input-group-text id="tooltip-jenis">
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>
              <div
                class="col-md-3"
                v-if="
                  form.identity_status == '1' || form.identity_status == '2'
                "
              >
                <div class="form-group">
                  <label for="identity_number">
                    {{ form.identity_status == "1" ? "NIK :" : "NIB :" }}
                  </label>
                  <input
                    v-model="form.identity_number"
                    type="number"
                    class="form-control"
                    :placeholder="form.identity_status === '1' ? 'NIK' : 'NIB'"
                    required
                    @input="validasiNik()"
                    :class="{
                      'is-valid': form.identity_number != '',
                      'is-invalid':
                        formValidate.identity_number ||
                        form.identity_number == '',
                    }"
                  />
                  <small
                    class="text-danger"
                    v-html="formValidate.identity_number"
                  ></small>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label for="customer_address">Alamat: </label>
                  <textarea
                    v-model="form.customer_address"
                    class="form-control"
                    name="customer_address"
                    id="customer_address"
                    placeholder="Alamat"
                    rows="2"
                    required
                    @input="formValidate.customer_address = ''"
                    :class="{
                      'is-valid': form.customer_address != '',
                      'is-invalid':
                        formValidate.customer_address ||
                        form.customer_address == '',
                    }"
                  ></textarea>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="district">Kecamatan : </label>
                  <select
                    disabled
                    v-model="form.district"
                    name="district"
                    id="district"
                    v-on:change="fetchVillages($event)"
                    class="form-control"
                    @change="formValidate.district = ''"
                    :class="{
                      'is-valid': form.district != '',
                      'is-invalid':
                        formValidate.district || form.district == '',
                    }"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="districts in districtList"
                      :value="districts.id_kec"
                      :key="districts.id_kec"
                    >
                      {{ districts.nama_kecamatan }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="village">Kelurahan : </label>
                  <select
                    disabled
                    v-model="form.village"
                    name="village"
                    id="village"
                    class="form-control"
                    @change="formValidate.village = ''"
                    :class="{
                      'is-valid': form.village != '',
                      'is-invalid': formValidate.village || form.village == '',
                    }"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="villages in villageList"
                      :value="villages.id_kel"
                      :key="villages.id_kel"
                    >
                      {{ villages.nama_kelurahan }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="hamlet">RW : </label>
                  <input
                    disabled
                    v-model="form.hamlet"
                    type="number"
                    class="form-control"
                    name="hamlet"
                    placeholder="Contoh : 001"
                    @input="formValidate.hamlet = ''"
                    :class="{
                      'is-valid': form.hamlet != '',
                      'is-invalid': formValidate.hamlet || form.hamlet == '',
                    }"
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="neighbourhood">RT : </label>
                  <input
                    disabled
                    v-model="form.neighbourhood"
                    type="number"
                    class="form-control"
                    name="neighbourhood"
                    placeholder="Contoh : 001"
                    @input="formValidate.neighbourhood = ''"
                    :class="{
                      'is-valid': form.neighbourhood != '',
                      'is-invalid':
                        formValidate.neighbourhood || form.neighbourhood == '',
                    }"
                  />
                </div>
              </div>

              <div class="col-md-2" v-if="form.identity_status == '1'">
                <div class="form-group">
                  <label for="employment_status">Status ASN/Sipil : </label>
                  <select
                    v-model="form.employment_status"
                    name="employment_status"
                    id="employment_status"
                    @change="
                      formValidate.employment_status = '';
                      form.employment_status === '1'
                        ? (form.customer_categories =
                            '6fece19d-7f0d-4ab9-ab91-53f207fe979b')
                        : (form.customer_categories = form.customer_categories);
                    "
                    class="form-control"
                    :class="{
                      'is-valid': form.employment_status != '',
                      'is-invalid':
                        formValidate.employment_status ||
                        form.employment_status == '',
                    }"
                  >
                    <option value="">Pilih</option>
                    <option value="1">ASN Kota Palu</option>
                    <option value="2">ASN Non Kota Palu</option>
                    <option value="3">Sipil</option>
                  </select>
                </div>
              </div>

              <div class="col-md-5" v-if="form.employment_status == '1'">
                <div class="form-group">
                  <label for="institutions">Instansi : </label>
                  <select
                    v-model="form.institutions"
                    class="form-control"
                    name="institutions"
                    required
                    @change="formValidate.institutions = ''"
                    :class="{
                      'is-valid': form.institutions != '',
                      'is-invalid':
                        formValidate.institutions || form.institutions == '',
                    }"
                  >
                    <option value="">Pilih Instansi</option>
                    <option
                      v-for="instansi in instansiList"
                      :key="instansi.government_agency_id"
                      :value="instansi.government_agency_id"
                    >
                      {{ instansi.government_agency_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label for="customer_categories">Jenis Kegiatan : </label>
                  <select
                    v-model="form.customer_categories"
                    class="form-control"
                    name="customer_categories"
                    required
                    @change="formValidate.customer_categories = ''"
                    :class="{
                      'is-valid': form.customer_categories != '',
                      'is-invalid':
                        formValidate.customer_categories ||
                        form.customer_categories == '',
                    }"
                  >
                    <option value="">Pilih Jenis Kegiatan</option>
                    <option
                      v-for="categories in categoiresList"
                      :key="categories.customer_category_id"
                      :value="categories.customer_category_id"
                    >
                      {{ categories.category_name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <button
              class="btn btn-success pull-right"
              @click="postData"
              variant="primary"
              :disabled="loading"
            >
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              Submit
            </button>
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      form: {
        customer_id: "",
        old_identity_number: "",
        identity_number: "",
        npwrd: "",
        customer_name: "",
        customer_address: "",
        district: "",
        village: "",
        hamlet: "",
        neighbourhood: "",
        customer_categories: "",
        created_by: "",
        created_at: "",
        longitude: "",
        latitude: "",
        identity_status: "",
        employment_status: "",
        institutions: "",
      },
      loading: false,
      showUserAccess: true,
      formValidate: [],
      categoiresList: [],
      districtList: [],
      villageList: [],
      instansiList: [],
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.form.customer_id = id;
      this.fetchData();
    }
    this.fetchCategories();
    this.fetchDistricts();
    this.fetchInstansi();
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post("/v1/customers/update/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      axios
        .get("/v1/customers/detail/" + this.form.customer_id)
        .then((response) => {
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
          }
          this.form.old_identity_number = response.data.data.identity_number;
          if (this.form.district) {
            axios
              .get("/v1/daerah/kelurahan/" + this.form.district, {
                headers: {
                  token: this.userToken,
                },
              })
              .then((response) => {
                this.villageList = response.data.data;
              })
              .catch((error) => {
                console.log(error);
                this.error = error.message;
              });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchCategories() {
      axios
        .get("/v1/customer_categories", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.categoiresList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchInstansi() {
      axios
        .get("/v1/government_agencies", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.instansiList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchDistricts() {
      axios
        .get("/v1/daerah/kecamatan/7271", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.districtList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchVillages(event) {
      axios
        .get("/v1/daerah/kelurahan/" + event.target.value, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.villageList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    validasiNik() {
      this.formValidate.identity_number = "";
      if (this.form.identity_status == "1") {
        if (this.form.identity_number.length < 16) {
          this.formValidate.identity_number =
            "NIK Kurang " +
            (16 - this.form.identity_number.length) +
            " Digit !";
        } else if (this.form.identity_number.length > 16) {
          this.formValidate.identity_number =
            "NIK Kelebihan " +
            (this.form.identity_number.length - 16) +
            " Digit !";
        }
      } else if (this.form.identity_status == "2") {
        if (this.form.identity_number.length < 13) {
          this.formValidate.identity_number =
            "NIB Kurang " +
            (13 - this.form.identity_number.length) +
            " Digit !";
        } else if (this.form.identity_number.length > 13) {
          this.formValidate.identity_number =
            "NIB Kelebihan " +
            (this.form.identity_number.length - 13) +
            " Digit !";
        }
      }
    },
  },
};
</script>
