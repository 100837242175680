<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">Rekening Koran</h3>
        </div>
        <div class="panel-body p-5">
          <div class="row mb-2">
            <div class="col-md-8">
              <input
                class="form-control"
                type="file"
                @change="handleFileUpload"
              />
            </div>
            <div class="col-md-2">
              <button class="btn btn-info btn-block" @click="importData">
                Read Data
              </button>
            </div>
            <div class="col-md-2">
              <button class="btn btn-success btn-block" @click="postData">
                Send Data
              </button>
            </div>
          </div>
          <vue-good-table
            :search-options="{
              enabled: true,
              placeholder: 'Pencarian',
            }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: true,
              mode: 'records',
              perPage: 20,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: true,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div>
                  <!-- <button
                    class="btn btn-danger btn-circle btn-icon"
                    v-on:click="confirmDelete(props.row.customer_category_id)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button> -->
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
        <div class="panel-footer">
          <a href="javascript:window.history.go(-1);" class="btn btn-default"
            >Kembali</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../../plugins/sipData";
import ExcelJS from "exceljs";
import moment from "moment-timezone";

export default {
  name: "DataList",
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "Kode",
          field: "transaction_code",
          sortable: false,
          width: "150px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Posting Date",
          field: "posting_date",
          sortable: false,
          width: "200px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Remark",
          field: "remark",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-wrap",
        },
        {
          label: "Credit",
          field: "credit",
          sortable: false,
          width: "150px",
          formatFn: this.currencyFormat,
          thClass: "text-center text-nowrap",
          tdClass: "text-right text-nowrap",
        },
      ],
      rows: [],
      tableInfo: "Empty data",
      isLoading: false,
      formValidate: [],
      file: null,
    };
  },
  mounted() {
    // this.fetchData();
  },
  methods: {
    handleFileUpload(e) {
      this.file = e.target.files[0];
    },
    importData() {
      if (this.file) {
        const workbook = new ExcelJS.Workbook();
        const reader = new FileReader();

        reader.onload = async (e) => {
          const arrayBuffer = e.target.result;
          const buffer = Buffer.from(arrayBuffer);
          await workbook.xlsx.load(buffer);

          const worksheet = workbook.getWorksheet(1);
          const importedData = [];
          worksheet.eachRow((row) => {
            if (row.number > 1) {
              const postingDateValue = row.values[1];

              const isDesiredFormat =
                /^\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \(.+\)$/.test(
                  postingDateValue
                );

              let postingDate;
              let formattedPostingDate;

              if (isDesiredFormat) {
                postingDate = new Date(postingDateValue);
                formattedPostingDate = postingDate
                  .toISOString()
                  .slice(0, 19)
                  .replace("T", " ");
              } else {
                const zonaWaktuLocal = moment.tz.guess();
                postingDate = moment(
                  postingDateValue,
                  "DD/MM/YYYY HH:mm:ss"
                ).toDate();
                formattedPostingDate = moment
                  .tz(postingDateValue, "DD/MM/YYYY HH:mm:ss", zonaWaktuLocal)
                  .format("YYYY-MM-DD HH:mm:ss");
                // formattedPostingDate = postingDate
                //   .toISOString()
                //   .slice(0, 19)
                //   .replace("T", " ");
              }

              const creditValue = row.values[3].toString();
              const credit = creditValue.split(",")[0].replace(/\./g, "");

              const timestamp = postingDate.getTime();
              const randomLetters = this.generateRandomLetters(3);
              const transactionCode = `mdr_${timestamp}${randomLetters}`;

              const rowData = {
                transaction_code: transactionCode,
                posting_date: formattedPostingDate,
                remark: row.values[2],
                credit: credit,
              };
              importedData.push(rowData);
            }
          });
          this.rows = importedData;
        };

        reader.readAsArrayBuffer(this.file);
      }
    },
    postData() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("data_import", JSON.stringify(this.rows));
      axios
        .post("/v1/checking_account/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.isLoading = false;
            this.formValidate = response.data.message;
          } else {
            this.isLoading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            this.modalVisible = false;
            this.conditionMet = true;
            setTimeout(() => {
              Swal.close();
              this.$router.go(-1);
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    currencyFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    generateRandomLetters(length) {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    },
  },
};
</script>