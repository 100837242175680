<template>
  <div>
    <span
      v-if="
        dataToDisplay &&
        dataToDisplay != 'Invalid date' &&
        dataToDisplay != ' / '
      "
    >
      {{ dataToDisplay }}
    </span>
    <span v-else class="skeleton-loader"></span>
  </div>
</template>

<script>
export default {
  props: {
    dataToDisplay: {
      type: [String, Number, Object, Array, Boolean],
      default: null,
    },
  },
};
</script>
<style scoped>
.skeleton-loader {
  width: 100%;
  height: 20px;
  display: block;
  border-radius: 3px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}
</style>

