<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">List Pengguna</h3>
        </div>
        <div class="panel-body">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :lineNumbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Pencarian',
            }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            }"
          >
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-default mr-2"
                >
                  <i
                    class="fa fa-sync"
                    :class="{ 'fa-spin': this.isLoading }"
                  ></i>
                </button>
                <router-link to="/pengguna/create" class="btn btn-primary">
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Tambah Pengguna</span>
                </router-link>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'status'">
                <div class="switcher" style="margin-top: 5px">
                  <input
                    type="checkbox"
                    :name="props.row.id_users"
                    :id="props.row.id_users"
                    :checked="parseInt(props.row.login_status)"
                    v-on:click="
                      toggleUser(props.row.id_users, props.row.login_status)
                    "
                  />
                  <label :for="props.row.id_users"></label>
                </div>
              </span>
              <span v-if="props.column.field == 'action'">
                <div>
                  <router-link
                    :to="'/pengguna/password/' + props.row.id_users"
                    class="btn btn-circle btn-icon btn-info m-r-5"
                  >
                    <i class="fa fa-key"></i>
                  </router-link>
                  <router-link
                    :to="'/pengguna/update/' + props.row.id_users"
                    class="btn btn-circle btn-icon btn-warning m-r-5"
                  >
                    <i class="fa fa-pencil-alt"></i>
                  </router-link>
                  <button
                    class="btn btn-circle btn-icon btn-danger"
                    v-on:click="confirmDelete(props.row.id_users)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  data() {
    return {
      columns: [
        {
          label: "Nama",
          field: "full_name",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Username",
          field: "username",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "No. HP",
          field: "phone_number",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Level",
          field: "level",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap text-capitalize",
        },
        {
          label: "Terakhir Login",
          field: "last_login",
          sortable: false,
          formatFn: this.timeStampFormat,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap valign-middle",
        },
        {
          label: "Status Login",
          field: "status",
          sortable: false,
          width: "120px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap valign-middle",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      isLoading: false,
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      axios
        .get("/v1/users")
        .then((response) => {
          this.rows = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/users/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },

    toggleUser(id, status) {
      try {
        axios
          .get("/v1/users/toggle/", {
            headers: {
              token: this.userToken,
            },
            params: {
              id: id,
              status: status,
            },
          })
          .then((response) => {
            // console.log(response.status);
            if (response.status === 201) {
              this.fetchData();
              // this.isChecked = true;
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    timeStampFormat(dateTime) {
      if (dateTime) {
        return moment.unix(dateTime).format("D MMMM YYYY");
      } else {
        return "-";
      }
    },
  },
};
</script>