<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Pelanggan</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12 mb-3">
                <button
                  v-if="showPotensial"
                  class="btn btn-info"
                  @click="showPotensial = false"
                >
                  Tutup Data Potensial
                </button>
                <button
                  v-else
                  class="btn btn-info"
                  @click="showPotensial = true"
                >
                  Buka Data Potensial
                </button>
              </div>
              <div class="col-md-12" v-if="showPotensial">
                <vue-good-table
                  mode="remote"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-search="onSearch"
                  :totalRows="totalRecords"
                  :isLoading.sync="isLoading"
                  :search-options="{
                    enabled: true,
                    trigger: 'keyup',
                    placeholder: 'Pencarian',
                  }"
                  :pagination-options="{
                    enabled: true,
                    perPageDropdownEnabled: false,
                    perPageDropdown: [10, 20, 30, 40, 50, 100],
                    dropdownAllowAll: false,
                    position: 'bottom',
                    rowsPerPageLabel: '',
                    nextLabel: '',
                    prevLabel: '',
                    ofLabel: 'of',
                    pageLabel: 'page',
                    allLabel: '',
                  }"
                  :rows="rows"
                  :columns="columns"
                >
                  <div slot="emptystate" class="text-center">
                    {{ tableInfo }}
                  </div>
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'action'">
                      <div>
                        <button
                          class="btn btn-sm btn-block btn-primary"
                          v-on:click="fetchCostumers(props.row.customer_id)"
                        >
                          Pilih
                        </button>
                      </div>
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </div>
              <div class="col-md-12">
                <hr />
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-6"
                :class="{
                  'col-md-9':
                    form.identity_status == '3' || form.identity_status == '',
                }"
              >
                <div class="form-group">
                  <label for="customer_name">Nama: </label>
                  <input
                    v-model="form.customer_name"
                    type="text"
                    class="form-control"
                    placeholder="Nama"
                    required
                    @input="formValidate.customer_name = ''"
                    :class="{
                      'is-valid': form.customer_name != '',
                      'is-invalid':
                        formValidate.customer_name || form.customer_name == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="identity_status">Jenis Identitas : </label>
                  <b-input-group>
                    <b-form-select
                      v-model="form.identity_status"
                      name="identity_status"
                      id="identity_status"
                      @change="formValidate.identity_status = ''"
                      :class="{
                        'is-valid': form.identity_status != '',
                        'is-invalid':
                          formValidate.identity_status ||
                          form.identity_status == '',
                      }"
                    >
                      <option value="">Pilih Jenis Identitas</option>
                      <option value="1">NIK (Nomor Induk Kependudukan)</option>
                      <option value="2">NIB (Nomor Induk Berusaha)</option>
                      <option value="3">Lainnya</option>
                    </b-form-select>
                    <b-input-group-append>
                      <b-tooltip
                        target="tooltip-jenis"
                        triggers="hover focus"
                        placement="top"
                      >
                        <ul
                          style="
                            text-align: left;
                            margin: 0;
                            padding: 5px 5px 5px 15px;
                          "
                        >
                          <li>NIK Khusus untuk perorangan</li>
                          <li>NIB Khusus untuk usaha yang memiliki NIB</li>
                        </ul>
                      </b-tooltip>
                      <b-input-group-text id="tooltip-jenis">
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>
              <div
                class="col-md-3"
                v-if="
                  form.identity_status == '1' || form.identity_status == '2'
                "
              >
                <div class="form-group">
                  <label for="identity_number">
                    {{ form.identity_status == "1" ? "NIK :" : "NIB :" }}
                  </label>
                  <input
                    v-model="form.identity_number"
                    type="number"
                    class="form-control"
                    :placeholder="form.identity_status === '1' ? 'NIK' : 'NIB'"
                    required
                    @input="validasiNik()"
                    :class="{
                      'is-valid': form.identity_number != '',
                      'is-invalid':
                        formValidate.identity_number ||
                        form.identity_number == '',
                    }"
                  />
                  <small
                    class="text-danger"
                    v-html="formValidate.identity_number"
                  ></small>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label for="customer_address">Alamat: </label>
                  <textarea
                    v-model="form.customer_address"
                    class="form-control"
                    name="customer_address"
                    id="customer_address"
                    placeholder="Alamat"
                    rows="2"
                    required
                    @input="formValidate.customer_address = ''"
                    :class="{
                      'is-valid': form.customer_address != '',
                      'is-invalid':
                        formValidate.customer_address ||
                        form.customer_address == '',
                    }"
                  ></textarea>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="district">Kecamatan : </label>
                  <select
                    v-model="form.district"
                    name="district"
                    id="district"
                    v-on:change="fetchVillages($event)"
                    class="form-control"
                    @change="formValidate.district = ''"
                    :class="{
                      'is-valid': form.district != '',
                      'is-invalid':
                        formValidate.district || form.district == '',
                    }"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="districts in districtList"
                      :value="districts.id_kec"
                      :key="districts.id_kec"
                    >
                      {{ districts.nama_kecamatan }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="village">Kelurahan : </label>
                  <select
                    v-model="form.village"
                    name="village"
                    id="village"
                    class="form-control"
                    @change="formValidate.village = ''"
                    :class="{
                      'is-valid': form.village != '',
                      'is-invalid': formValidate.village || form.village == '',
                    }"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="villages in villageList"
                      :value="villages.id_kel"
                      :key="villages.id_kel"
                    >
                      {{ villages.nama_kelurahan }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="hamlet">RW : </label>
                  <input
                    v-model="form.hamlet"
                    type="number"
                    class="form-control"
                    name="hamlet"
                    placeholder="Contoh : 001"
                    @input="formValidate.hamlet = ''"
                    :class="{
                      'is-valid': form.hamlet != '',
                      'is-invalid': formValidate.hamlet || form.hamlet == '',
                    }"
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="neighbourhood">RT : </label>
                  <input
                    v-model="form.neighbourhood"
                    type="number"
                    class="form-control"
                    name="neighbourhood"
                    placeholder="Contoh : 001"
                    @input="formValidate.neighbourhood = ''"
                    :class="{
                      'is-valid': form.neighbourhood != '',
                      'is-invalid':
                        formValidate.neighbourhood || form.neighbourhood == '',
                    }"
                  />
                </div>
              </div>

              <div class="col-md-2" v-if="form.identity_status == '1'">
                <div class="form-group">
                  <label for="employment_status">Status ASN/Sipil : </label>
                  <select
                    v-model="form.employment_status"
                    name="employment_status"
                    id="employment_status"
                    @change="
                      formValidate.employment_status = '';
                      form.employment_status === '1'
                        ? (form.customer_categories =
                            '6fece19d-7f0d-4ab9-ab91-53f207fe979b')
                        : (form.customer_categories = '');
                    "
                    class="form-control"
                    :class="{
                      'is-valid': form.employment_status != '',
                      'is-invalid':
                        formValidate.employment_status ||
                        form.employment_status == '',
                    }"
                  >
                    <option value="">Pilih</option>
                    <option value="1">ASN Kota Palu</option>
                    <option value="2">ASN Non Kota Palu</option>
                    <option value="3">Sipil</option>
                  </select>
                </div>
              </div>

              <div class="col-md-5" v-if="form.employment_status == '1'">
                <div class="form-group">
                  <label for="institutions">Instansi : </label>
                  <select
                    v-model="form.institutions"
                    class="form-control"
                    name="institutions"
                    required
                    @change="formValidate.institutions = ''"
                    :class="{
                      'is-valid': form.institutions != '',
                      'is-invalid':
                        formValidate.institutions || form.institutions == '',
                    }"
                  >
                    <option value="">Pilih Instansi</option>
                    <option
                      v-for="instansi in instansiList"
                      :key="instansi.government_agency_id"
                      :value="instansi.government_agency_id"
                    >
                      {{ instansi.government_agency_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label for="customer_categories">Jenis Kegiatan : </label>
                  <select
                    v-model="form.customer_categories"
                    class="form-control"
                    name="customer_categories"
                    required
                    @change="formValidate.customer_categories = ''"
                    :class="{
                      'is-valid': form.customer_categories != '',
                      'is-invalid':
                        formValidate.customer_categories ||
                        form.customer_categories == '',
                    }"
                  >
                    <option value="">Pilih Jenis Kegiatan</option>
                    <option
                      v-for="categories in categoiresList"
                      :key="categories.customer_category_id"
                      :value="categories.customer_category_id"
                    >
                      {{ categories.category_name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <button
              class="btn btn-success pull-right"
              @click="postData"
              variant="primary"
              :disabled="loading"
            >
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              Submit
            </button>
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "Nama",
          field: "customer_name",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Alamat",
          field: "customer_address",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Kelurahan",
          field: "village_name",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "RW",
          field: "hamlet",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "RT",
          field: "neighbourhood",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass: "text-center text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      form: {
        customer_id: "",
        identity_number: "",
        npwrd: "",
        customer_name: "",
        customer_address: "",
        district: "",
        village: "",
        hamlet: "",
        neighbourhood: "",
        customer_categories: "",
        created_by: "",
        created_at: "",
        longitude: "",
        latitude: "",
        identity_status: "",
        employment_status: "",
        institutions: "",
      },
      loading: false,
      showUserAccess: true,
      formValidate: [],
      categoiresList: [],
      districtList: [],
      villageList: [],
      instansiList: [],

      tableInfo: "Silahkan Masukan Nama Pelanggan",
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      searchTerm: "",
      timeoutSearch: null,
      loadDPT: false,
      searchRemark: "",
      dataRemark: [],
      showPotensial: false,
    };
  },
  mounted() {
    this.fetchCategories();
    this.fetchDistricts();
    this.fetchInstansi();
    this.getLocation();
  },
  methods: {
    postData() {
      this.loading = true;
      if (this.form.identity_status == "") {
        this.formValidate.identity_status = "Pilih Jenis Identitas";
        this.loading = false;
      } else {
        const formData = new FormData();
        for (let key in this.form) {
          formData.append(key, this.form[key]);
        }
        axios
          .post("/v1/customers/create/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              token: this.userToken,
            },
          })
          .then((response) => {
            if (response.data.validasi == false) {
              this.loading = false;
              this.formValidate = response.data.message;
            } else {
              this.loading = false;
              Swal.fire({
                title: response.data.title,
                text: response.data.message,
                icon: response.data.icon,
                showCancelButton: false,
                showConfirmButton: false,
              });
              setTimeout(() => {
                Swal.close();
                if (response.data.status) {
                  this.$router.go(-1);
                }
              }, 700);
            }
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    fetchCategories() {
      axios
        .get("/v1/customer_categories", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.categoiresList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchInstansi() {
      axios
        .get("/v1/government_agencies", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.instansiList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchDistricts() {
      axios
        .get("/v1/daerah/kecamatan/7271", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.districtList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchVillages(event) {
      axios
        .get("/v1/daerah/kelurahan/" + event.target.value, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.villageList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    onSearch(params) {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(() => {
        this.searchTerm = params.searchTerm;
        if (this.searchTerm) {
          this.tableInfo = "Mengambil data";
          this.fetchData();
        } else {
          this.tableInfo = "Silahkan Masukan Nama Pelanggan";
          this.rows = [];
        }
      }, 500);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchData();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.fetchData();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.fetchData();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.fetchData();
    },

    fetchData() {
      axios
        .get("/v1/old_customers/search", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.total;
          if (this.totalRecords <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchCostumers(params) {
      axios
        .get("/v1/old_customers/detail/" + params)
        .then((response) => {
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
          }
          axios
            .get("/v1/daerah/kelurahan/" + this.form.district, {
              headers: {
                token: this.userToken,
              },
            })
            .then((response) => {
              this.villageList = response.data.data;
            })
            .catch((error) => {
              console.log(error);
              this.error = error.message;
            });
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        );
      } else {
        alert("Geolokasi tidak didukung di browser ini.");
      }
    },
    showPosition(position) {
      this.form.latitude = position.coords.latitude;
      this.form.longitude = position.coords.longitude;
    },
    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("Akses ke lokasi ditolak oleh pengguna.");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Informasi lokasi tidak tersedia.");
          break;
        case error.TIMEOUT:
          alert("Waktu permintaan lokasi habis.");
          break;
        case error.UNKNOWN_ERROR:
          alert("Terjadi kesalahan yang tidak diketahui.");
          break;
      }
    },
    validasiNik() {
      this.formValidate.identity_number = "";
      if (this.form.identity_status == "1") {
        if (this.form.identity_number.length < 16) {
          this.formValidate.identity_number =
            "NIK Kurang " +
            (16 - this.form.identity_number.length) +
            " Digit !";
        } else if (this.form.identity_number.length > 16) {
          this.formValidate.identity_number =
            "NIK Kelebihan " +
            (this.form.identity_number.length - 16) +
            " Digit !";
        }
      } else if (this.form.identity_status == "2") {
        if (this.form.identity_number.length < 13) {
          this.formValidate.identity_number =
            "NIB Kurang " +
            (13 - this.form.identity_number.length) +
            " Digit !";
        } else if (this.form.identity_number.length > 13) {
          this.formValidate.identity_number =
            "NIB Kelebihan " +
            (this.form.identity_number.length - 13) +
            " Digit !";
        }
      }
    },
  },
};
</script>
