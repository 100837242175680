<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Detail Transaksi</h3>
          </div>
          <div class="panel-body">
            <div
              class="alert alert-info fade show f-s-13"
              style="margin-bottom: 5px"
            >
              <table class="table">
                <tr>
                  <td style="width: 200px">Nama</td>
                  <td style="width: 10px">:</td>
                  <td>
                    <LazyLoad
                      :data-to-display="detail.customer_name"
                    ></LazyLoad>
                  </td>
                </tr>
                <tr>
                  <td>NPWRD</td>
                  <td>:</td>
                  <td>
                    <LazyLoad :data-to-display="detail.npwrd"></LazyLoad>
                  </td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>:</td>
                  <td>
                    <LazyLoad
                      :data-to-display="detail.customer_address"
                    ></LazyLoad>
                  </td>
                </tr>
                <tr>
                  <td>Kecamatan</td>
                  <td>:</td>
                  <td>
                    <LazyLoad
                      :data-to-display="detail.district_name"
                    ></LazyLoad>
                  </td>
                </tr>
                <tr>
                  <td>Kelurahan</td>
                  <td>:</td>
                  <td>
                    <LazyLoad :data-to-display="detail.village_name"></LazyLoad>
                  </td>
                </tr>
                <tr>
                  <td>RW/RT</td>
                  <td>:</td>
                  <td>
                    <LazyLoad :data-to-display="rwrtData"></LazyLoad>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Jenis Kegiatan</td>
                  <td>:</td>
                  <td>
                    <LazyLoad
                      :data-to-display="detail.category_name"
                    ></LazyLoad>
                  </td>
                </tr>
                <tr>
                  <td>Satuan</td>
                  <td>:</td>
                  <td>
                    <LazyLoad
                      :data-to-display="this.currencyFormat(detail.cost)"
                    ></LazyLoad>
                  </td>
                </tr>
              </table>
            </div>
            <div
              class="alert alert-warning fade show f-s-13"
              style="margin-top: 5px"
            >
              <table class="table">
                <tr>
                  <td style="width: 200px">Periode Pembayaran</td>
                  <td style="width: 10px">:</td>
                  <td>
                    <LazyLoad
                      :data-to-display="detail.additional_details"
                    ></LazyLoad>
                  </td>
                </tr>
                <tr>
                  <td>Jumlah Bayar</td>
                  <td>:</td>
                  <td>
                    <LazyLoad
                      :data-to-display="
                        this.currencyFormat(detail.payment_amount)
                      "
                    ></LazyLoad>
                  </td>
                </tr>
                <tr>
                  <td>Tanggal Transaksi</td>
                  <td>:</td>
                  <td>
                    <LazyLoad
                      :data-to-display="
                        this.indonesianFormat(detail.transaction_date)
                      "
                    ></LazyLoad>
                  </td>
                </tr>
                <tr>
                  <td>Diinput Oleh</td>
                  <td>:</td>
                  <td>
                    <LazyLoad :data-to-display="detail.created_name"></LazyLoad>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="panel-footer">
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../../plugins/sipData";
import moment from "moment";
import LazyLoad from "../../components/loader/LazyLoad.vue";

export default {
  mixins: [sipData],
  components: {
    LazyLoad,
  },
  data() {
    return {
      detail: {
        transaction_id: "",
        customer_id: "",
        npwrd: "",
        customer_name: "",
        customer_address: "",
        district: "",
        village: "",
        hamlet: "",
        neighbourhood: "",
        customer_categories: "",
        created_by: "",
        created_at: "",
        payment_amount: "",
        additional_details: "",
        transaction_date: "",
        created_name: "",
      },
      loading: false,
      showUserAccess: true,
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  computed: {
    rwrtData() {
      return `${this.detail.hamlet} / ${this.detail.neighbourhood}`;
    },
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.detail.transaction_id = id;
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/transactions/detail/" + this.detail.transaction_id)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    indonesianFormat(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    timeStampFormat(dateTime) {
      return moment.unix(dateTime).format("D MMMM YYYY");
    },
    currencyFormat(value) {
      if (!value) return "";
      return "Rp. " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>