<template>
  <div class="print-layout">
    <button
      v-if="detail.npwrd"
      @click="goPrint()"
      class="btn btn-float-bottom btn-info"
    >
      <i class="fa fa-print"></i>
    </button>
    <button @click="goBack()" class="btn btn-float-top btn-primary">
      <i class="fa fa-arrow-left"></i>
    </button>
    <div id="ssrd" style="margin-top: 5px">
      <table class="tabel-border">
        <tr>
          <td class="teks-center">
            <div class="d-flex">
              <img
                src="../../assets/logo-palu.png"
                style="height: 100px"
                alt=""
              />
              <div style="padding: 0.5em 2em">
                <h1>DINAS LINGKUNGAN HIDUP</h1>
                <h1>KOTA PALU</h1>
              </div>
            </div>
          </td>
          <td class="teks-center" style="width: 420px">
            <h1>SSRD</h1>
            <h4>SURAT SETORAN RETRIBUSI DAERAH</h4>
          </td>
        </tr>
      </table>
      <table class="tabel-border">
        <tr class="teks-center bold">
          <td>No</td>
          <td>Kode Rekening</td>
          <td>Jenis Retribusi</td>
          <td>Besaran Tarif</td>
        </tr>
        <tr>
          <td rowspan="2" class="teks-center bold">1</td>
          <td rowspan="2" class="teks-center bold">1.2.2 002</td>
          <td class="teks-center bold">
            <p>RETRIBUSI PELAYANAN PERSAMPAHAN</p>
            <p>{{ detail.category_name }}</p>
            <p>{{ detail.additional_details }}</p>
          </td>
          <td class="bold">
            <span>Rp</span>
            <span class="push-right">{{
              this.currencyFormat(detail.payment_amount)
            }}</span>
          </td>
        </tr>
        <tr class="bold">
          <td>Jumlah Ketetapan Pokok Retribusi</td>
          <td>
            <span>Rp</span>
            <span class="push-right">{{
              this.currencyFormat(detail.payment_amount)
            }}</span>
          </td>
        </tr>
        <tr class="bold">
          <td colspan="2" class="teks-center">DENGAN HURUF</td>
          <td colspan="2">{{ this.terbilang(detail.payment_amount) }}</td>
        </tr>
      </table>
      <table class="tabel-border">
        <tr class="bold">
          <td>
            <div class="teks-center">
              <br />
              <br />
              <br />
              <p>Bendahara Penerima</p>
              <img
                style="height: 100px"
                src="../../assets/img/ttd/ttd-syawal.svg"
                alt=""
              />
              <p class="underline">SYAWAL IBRAHIM</p>
              <p>NIP. 19890525 201908 1 001</p>
            </div>
          </td>
          <td>
            <div class="teks-center">
              <br />
              <br />
              <br />
              <p>Operator/Teller</p>
              <div style="height: 100px"></div>
              <p class="underline text-uppercase">{{ userName }}</p>
              <br />
            </div>
          </td>
          <td style="width: 420px">
            <div class="teks-center">
              <p>Palu, {{ this.indonesianFormat(detail.transaction_date) }}</p>
              <p>Yang Menetapkan</p>
              <p>KEPALA DINAS LINGKUNGAN HIDUP</p>
              <p>KOTA PALU</p>
              <img
                style="height: 100px"
                src="../../assets/img/ttd/ttd-kadis.svg"
                alt=""
              />
              <p class="underline">MOHAMAD ARIF, S.STP., M.Si.</p>
              <p>NIP. 19780819 1996112 1 001</p>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";
import axios from "axios";
import sipData from "../../plugins/sipData";
import moment from "moment";

export default {
  mixins: [sipData],
  data() {
    return {
      detail: {
        transaction_id: "",
        customer_id: "",
        npwrd: "",
        customer_name: "",
        customer_address: "",
        district: "",
        village: "",
        hamlet: "",
        neighbourhood: "",
        customer_categories: "",
        created_by: "",
        created_at: "",
        payment_amount: "",
        additional_details: "",
        transaction_date: "",
      },
      loading: false,
      showUserAccess: true,
    };
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.detail.transaction_id = id;
      this.fetchData();
    }
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goPrint() {
      window.print();
    },
    fetchData() {
      axios
        .get("/v1/transactions/detail/" + this.detail.transaction_id)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    indonesianFormat(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    timeStampFormat(dateTime) {
      return moment.unix(dateTime).format("D MMMM YYYY");
    },
    currencyFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    terbilang(number) {
      const satuan = [
        "",
        "Satu",
        "Dua",
        "Tiga",
        "Empat",
        "Lima",
        "Enam",
        "Tujuh",
        "Delapan",
        "Sembilan",
      ];
      const belasan = [
        "",
        "Sebelas",
        "Dua Belas",
        "Tiga Belas",
        "Empat Belas",
        "Lima Belas",
        "Enam Belas",
        "Tujuh Belas",
        "Delapan Belas",
        "Sembilan Belas",
      ];
      const puluhan = [
        "",
        "Sepuluh",
        "Dua Puluh",
        "Tiga Puluh",
        "Empat Puluh",
        "Lima Puluh",
        "Enam Puluh",
        "Tujuh Puluh",
        "Delapan Puluh",
        "Sembilan Puluh",
      ];
      const ribu = ["", "Ribu", "Juta", "Miliar", "Triliun"];

      let terbilangString = "";

      // Fungsi rekursif untuk konversi grup tiga digit
      function konversiGrupTigaDigit(num) {
        let result = "";

        if (num >= 100) {
          result +=
            num >= 200 ? satuan[Math.floor(num / 100)] + " Ratus " : "Seratus ";
          num %= 100;
        }

        if (num > 10 && num < 20) {
          result += belasan[num - 10] + " ";
        } else {
          result += puluhan[Math.floor(num / 10)] + " ";
          num %= 10;
          result += satuan[num] + " ";
        }

        return result;
      }

      let grupTigaDigitCount = 0;

      while (number > 0) {
        const currentGrupTigaDigit = number % 1000;
        if (currentGrupTigaDigit > 0) {
          terbilangString =
            konversiGrupTigaDigit(currentGrupTigaDigit) +
            ribu[grupTigaDigitCount] +
            " " +
            terbilangString;
        }

        number = Math.floor(number / 1000);
        grupTigaDigitCount++;
      }

      return terbilangString.trim() + " Rupiah";
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: "Times";
  src: url("../../assets/font/times-new-roman.ttf") format("truetype");
}
p {
  line-height: 10px;
}
.print-layout {
  padding: 1rem;
  font-family: "Times";
  font-size: 1.5em;
}
.tabel-border {
  border: 1px solid black !important;
  border-collapse: collapse !important;
  width: 100%;
}
.tabel-border tr td {
  border: 1px solid black !important;
  padding: 0.5em;
}
.logo-institusi {
  vertical-align: middle;
}
.teks-center {
  text-align: center;
}
.bold {
  font-weight: bold;
}
.d-flex {
  display: flex;
}
.push-right {
  float: right;
}
.underline {
  text-underline-position: under;
  text-decoration: underline;
}
.uppercase {
  text-transform: uppercase;
}
.w-10 {
  width: 10px;
}
.w-200 {
  width: 200px;
}
.w-400 {
  width: 400px;
}
.btn-float-bottom {
  width: 50px;
  height: 50px;
  line-height: 25px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  position: fixed;
  right: 30px;
  bottom: 30px;
}

.btn-float-top {
  width: 50px;
  height: 50px;
  line-height: 25px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  position: fixed;
  right: 30px;
  bottom: 90px;
}

@media print {
  .btn {
    display: none;
  }
  .table {
    width: 100%;
  }
}
</style>