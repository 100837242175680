<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel panel-primary">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Pengguna</h3>
          </div>
          <form
            @submit.prevent="postData"
            id="formPeserta"
            enctype="multipart/form-data"
          >
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="password"
                          >Password Lama
                          <span class="text-danger">*</span></label
                        >
                        <input
                          type="password"
                          v-model="form.old_password"
                          class="form-control"
                          placeholder="Password Lama"
                          :class="{
                            'is-invalid': formValidate.old_password,
                          }"
                        />
                        <small
                          class="text-danger"
                          v-html="formValidate.old_password"
                        ></small>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="password"
                          >Password Baru
                          <span class="text-danger">*</span></label
                        >
                        <input
                          type="password"
                          v-model="form.new_password"
                          class="form-control"
                          placeholder="Password Baru"
                          :class="{
                            'is-invalid': formValidate.new_password,
                          }"
                        />
                        <small
                          class="text-danger"
                          v-html="formValidate.new_password"
                        ></small>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="confirm_password"
                          >Ulangi Password Baru
                          <span class="text-danger">*</span></label
                        >
                        <input
                          type="password"
                          v-model="form.confirm_password"
                          class="form-control"
                          placeholder="Ulangi Password Baru Anda"
                          :class="{
                            'is-invalid': formValidate.confirm_password,
                          }"
                        />
                        <small
                          class="text-danger"
                          v-html="formValidate.confirm_password"
                        ></small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-footer">
              <a
                href="javascript:window.history.go(-1);"
                class="btn btn-default"
                >Batal</a
              >
              <button
                type="button"
                class="btn btn-teal pull-right"
                @click="postData()"
                :disabled="this.loading"
              >
                <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
                {{ $route.params.id ? "Update" : "Submit" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      form: {
        id_users: "",
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
      formValidate: [],
      loading: false,
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.form.id_users = id;
    }
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();

      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post("/v1/users/password/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.$router.go(-1);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
