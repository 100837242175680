var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('div',{staticClass:"panel-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"lineNumbers":true,"search-options":{
            enabled: true,
            placeholder: 'Pencarian',
          },"pagination-options":{
            enabled: true,
            perPageDropdownEnabled: true,
            perPageDropdown: [10, 20, 30, 40, 50, 100],
            dropdownAllowAll: false,
            position: 'bottom',
            rowsPerPageLabel: '',
            nextLabel: '',
            prevLabel: '',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: '',
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'status')?_c('span',[_c('div',{staticClass:"switcher",staticStyle:{"margin-top":"5px"}},[_c('input',{attrs:{"type":"checkbox","name":props.row.id_users,"id":props.row.id_users},domProps:{"checked":parseInt(props.row.login_status)},on:{"click":function($event){return _vm.toggleUser(props.row.id_users, props.row.login_status)}}}),_c('label',{attrs:{"for":props.row.id_users}})])]):_vm._e(),(props.column.field == 'action')?_c('span',[_c('div',[_c('router-link',{staticClass:"btn btn-circle btn-icon btn-info m-r-5",attrs:{"to":'/pengguna/password/' + props.row.id_users}},[_c('i',{staticClass:"fa fa-key"})]),_c('router-link',{staticClass:"btn btn-circle btn-icon btn-warning m-r-5",attrs:{"to":'/pengguna/update/' + props.row.id_users}},[_c('i',{staticClass:"fa fa-pencil-alt"})]),_c('button',{staticClass:"btn btn-circle btn-icon btn-danger",on:{"click":function($event){return _vm.confirmDelete(props.row.id_users)}}},[_c('i',{staticClass:"fa fa-trash-alt"})])],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-default mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.fetchData()}}},[_c('i',{staticClass:"fa fa-sync",class:{ 'fa-spin': this.isLoading }})]),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/pengguna/create"}},[_c('i',{staticClass:"fa fa-plus d-md-none"}),_c('span',{staticClass:"d-none d-md-block"},[_vm._v("Tambah Pengguna")])])],1)])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-dark text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("List Pengguna")])])
}]

export { render, staticRenderFns }