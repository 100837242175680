<template>
  <div>
    <button
      class="pull-right btn btn-icon btn-circle btn-light"
      :class="{
        'fa-spin': this.isLoading,
      }"
      @click="fetchData()"
    >
      <i class="fa fa-sync"></i>
    </button>
    <h1 class="page-header">Dashboard <small></small></h1>
    <div class="row"></div>
    <div class="row">
      <div class="col-xl-4 col-md-4">
        <div class="widget widget-stats bg-teal">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-users fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">jUMLAH PELANGGAN</div>
            <div class="stats-number">{{ this.total_customer }}</div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-4">
        <div class="widget widget-stats bg-blue">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-ticket-alt fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">JUMLAH TRANSAKSI</div>
            <div class="stats-number">{{ this.total_transaction }}</div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-4">
        <div class="widget widget-stats bg-info">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-wallet fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">JUMLAH PENERIMAAN HARI INI</div>
            <div class="stats-number">
              {{ this.currencyFormat(this.total_payment_day) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-4" v-if="userLevel != 'kelurahan'">
        <div class="widget widget-stats bg-green">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-wallet fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">JUMLAH TOTAL PENERIMAAN TUNAI</div>
            <div class="stats-number">
              {{ this.currencyFormat(this.total_payment) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-4" v-if="userLevel != 'kelurahan'">
        <div class="widget widget-stats bg-green">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-wallet fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">JUMLAH TOTAL PENERIMAAN NON TUNAI</div>
            <div class="stats-number">
              {{
                this.currencyFormat(
                  this.total_online_payment - this.total_payment
                )
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-4" v-if="userLevel != 'kelurahan'">
        <div class="widget widget-stats bg-green">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-wallet fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">JUMLAH TOTAL PENERIMAAN</div>
            <div class="stats-number">
              {{ this.currencyFormat(this.total_online_payment) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        class="col-md-6 mb-3"
        :class="{
          'col-md-12': userLevel != 'kelurahan',
        }"
      >
        <div v-if="this.isLoading">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barDaily"></div>
      </div>
      <div class="col-md-6 mb-3" v-show="userLevel != 'kelurahan'">
        <div v-if="this.isLoading">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barUsers"></div>
      </div>
      <div
        class="col-md-6 mb-3"
        :class="{
          'col-md-6': userLevel != 'kelurahan',
        }"
      >
        <div v-if="this.isLoading">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barMonths"></div>
      </div>

      <div class="col-md-7 mb-3" v-show="userLevel != 'kelurahan'">
        <div v-if="this.isLoading">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="drilldownDistrict"></div>
      </div>
      <div class="col-md-5 mb-3" v-show="userLevel != 'kelurahan'">
        <div v-if="this.isLoading">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="pieDistrict"></div>
      </div>
      <div class="col-md-12 mb-3">
        <div v-if="this.isLoading">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barCategory" style="height: 90vh"></div>
      </div>
    </div>

    <div class="row" v-if="userLevel != 'kelurahan'">
      <div class="col-md-12 mb-3">
        <div v-if="this.isLoading">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barPerformance"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../plugins/sipData";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportingExcel from "highcharts/modules/export-data";
import accessibility from "highcharts/modules/accessibility";
import seriesLabel from "highcharts/modules/series-label";
import highContrastLight from "highcharts/themes/high-contrast-light";
import highContrastData from "highcharts/modules/data";
import moment from "moment";

// Inisialisasi modul Highcharts
drilldown(Highcharts);
exporting(Highcharts);
exportingExcel(Highcharts);
accessibility(Highcharts);
seriesLabel(Highcharts);
highContrastLight(Highcharts);
highContrastData(Highcharts);

export default {
  mixins: [sipData],
  name: "dashboard",
  data() {
    return {
      isLoading: false,
      total_customer: 0,
      total_transaction: 0,
      total_payment: 0,
      total_online_payment: 0,
      total_payment_day: 0,
      transaction_by_district: [],
      transaction_by_village: [],
      transaction_by_category: [],
      transaction_by_users: [],
      transaction_by_months: [],
      transaction_by_date: [],
      biller_performance: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      axios
        .get("/v1/dashboard/", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.total_customer = response.data.total_customer;
          this.total_transaction = response.data.total_transaction;
          this.total_payment = response.data.total_payment;
          this.total_online_payment = response.data.total_online_payment;
          this.total_payment_day = response.data.total_payment_day;
          this.transaction_by_district = response.data.transaction_by_district;
          this.transaction_by_village = response.data.transaction_by_village;
          this.transaction_by_category = response.data.transaction_by_category;
          this.transaction_by_users = response.data.transaction_by_users;
          this.transaction_by_months = response.data.transaction_by_months;
          this.transaction_by_date = response.data.transaction_by_date;
          this.biller_performance = response.data.biller_performance;
          this.isLoading = false;
          this.renderDrilDownDisctrict();
          this.renderPieDistrict();
          this.renderBarCategory();
          this.renderBarUsers();
          this.renderBarMonths();
          this.renderBarDaily();
          this.renderPerformance();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    renderDrilDownDisctrict() {
      Highcharts.chart(this.$refs.drilldownDistrict, {
        chart: {
          type: "bar",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Jumlah Transaksi Berdasarkan Kecamatan",
        },
        subtitle: {
          text: "Klik Nama Kecamatan Untuk Melihat Detail/Kelurahan",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Penerimaan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value / 1000000 + " Juta" : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return "Rp. " + Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Rp. " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            data: this.transaction_by_district.map((data) => ({
              name: data.nama,
              y: parseInt(data.jumlah),
              drilldown: data.id_kec,
            })),
            colorByPoint: true,
          },
        ],
        drilldown: {
          series: this.transaction_by_district.map((kecamatanData) => ({
            id: kecamatanData.id_kec,
            name: kecamatanData.nama,
            data: this.drillDownDisctrict(kecamatanData),
          })),
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    drillDownDisctrict(kecamatanData) {
      const kelurahanData = this.transaction_by_village;
      const kecamatanId = kecamatanData.id_kec;

      return kelurahanData
        .filter((data) => data.id_kec === kecamatanId)
        .map((kelurahan) => ({
          name: kelurahan.nama,
          y: parseInt(kelurahan.jumlah),
        }));
    },
    renderPieDistrict() {
      Highcharts.chart(this.$refs.pieDistrict, {
        chart: {
          type: "pie",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Persentase Transaksi Berdasarkan Kecamatan",
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
              distance: 5,
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Rp. " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            colorByPoint: true,
            data: this.transaction_by_district.map((data) => ({
              name: data.nama,
              y: parseInt(data.jumlah),
              drilldown: data.id_kec,
            })),
          },
        ],
        drilldown: {
          series: this.transaction_by_district.map((kecamatanData) => ({
            id: kecamatanData.id_kec,
            name: kecamatanData.nama,
            data: this.drillDownDisctrict(kecamatanData),
          })),
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderBarCategory() {
      Highcharts.chart(this.$refs.barCategory, {
        chart: {
          type: "bar",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Jumlah Transaksi Berdasarkan Jenis Kegiatan",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Penerimaan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value / 1000000 + " Juta" : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return "Rp. " + Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Rp. " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            data: this.transaction_by_category.map((data) => ({
              name: data.category_name,
              y: parseInt(data.jumlah),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderBarUsers() {
      const jsonData = this.transaction_by_users;
      const userNames = jsonData.map((item) => item.nama_pengguna);
      const totalAmounts = jsonData.map((item) => parseInt(item.jumlah));
      const skrd = jsonData.map((item) => parseInt(item.skrd));
      const ssrd = jsonData.map((item) => parseInt(item.ssrd));

      Highcharts.chart(this.$refs.barUsers, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Jumlah Transaksi dan Penerimaan Hari Ini",
        },
        xAxis: {
          categories: userNames,
          crosshair: true,
          accessibility: {
            description: "Users",
          },
          labels: {
            formatter: function () {
              // Format nama sesuai kebutuhan Anda di sini
              // Misalnya, mengonversi setiap kata menjadi huruf besar
              return this.value
                .split(" ")
                .map(function (word) {
                  return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(" ");
            },
          },
        },
        yAxis: [
          {
            title: {
              text: "Jumlah Penerimaan",
            },
            labels: {
              formatter: function () {
                return this.value !== 0 ? this.value / 1000000 + " Juta" : "0";
              },
            },
          },
          {
            title: {
              text: "Jumlah Transaksi",
            },
            opposite: true,
          },
        ],
        tooltip: {
          shared: true,
          formatter: function () {
            return (
              "<b style='text-transform: capitalize'> " +
              this.x +
              "</b>" +
              "</b><br/>Jumlah Penerimaan : <b>Rp. " +
              Highcharts.numberFormat(this.points[2].y, 0, ",", ".") +
              "</b>" +
              "<br/>SKRD Dikeluarkan : <b>" +
              this.points[0].y +
              "</b><br/>SSRD Dikeluarkan : <b>" +
              this.points[1].y
            );
          },
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              rotation: -90,
              color: "#FFFFFF",
              align: "right",
              y: 10,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        series: [
          {
            name: "SKRD",
            data: skrd,
            yAxis: 1,
            color: "#49B6D6",
          },
          {
            name: "SSRD",
            data: ssrd,
            yAxis: 1,
            color: "#265FB5",
          },
          {
            name: "Nilai Transaksi",
            data: totalAmounts,
            color: "#698F01",
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderBarMonths() {
      Highcharts.chart(this.$refs.barMonths, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Jumlah Transaksi Per Bulan",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Penerimaan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value / 1000000 + " Juta" : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return "Rp. " + Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Rp. " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            data: this.transaction_by_months.map((data) => ({
              name: data.nama_bulan,
              y: parseInt(data.jumlah_transaksi),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderBarDaily() {
      Highcharts.chart(this.$refs.barDaily, {
        chart: {
          type: "spline",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Tren Transaksi Harian",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Penerimaan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value / 1000000 + " Juta" : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return "Rp. " + Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Rp. " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Jumlah Transaksi",
            data: this.transaction_by_date.map((data) => ({
              name: this.dateShortFormat(data.transaction_date),
              y: parseInt(data.jumlah_transaksi),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    currencyFormat(value) {
      if (!value) return "0";
      return "Rp. " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateShortFormat(dateTime) {
      return moment(dateTime).format("DD-MM-YY");
    },
    capitalizeEachWord(str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toLowerCase();
      });
    },

    renderPerformance() {
      Highcharts.chart(this.$refs.barPerformance, {
        chart: {
          type: "bar",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Performa Penagih",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          // tickInterval: 5,
          title: {
            text: "Jumlah",
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b> : " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Jumlah",
            data: this.biller_performance.map((data) => ({
              name: data.nama,
              y: parseInt(data.jumlah),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
  },
};
</script>