<template>
  <div id="footer" class="footer">
    &copy; 2024 {{ appName }} v{{ appVersion }}
    - All Rights Reserved
  </div>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";
import packageJson from "@/../package.json";

export default {
  name: "Footer",
  data() {
    return {
      pageOptions: PageOptions,
      appName: packageJson.title,
      appVersion: packageJson.version,
    };
  },
};
</script>
