<template>
  <div>
    <div class="login-cover">
      <div
        class="login-cover-image"
        style="background-image: url('../assets/img/bg-login.jpg')"
      ></div>
      <div class="login-cover-bg"></div>
    </div>
    <div class="login login-v2" data-pageload-addclass="animated fadeIn">
      <div class="bg-dark-transparent-9 rounded-lg m-5 p-5">
        <div class="login-header">
          <div class="brand text-center">
            <img
              src="../assets/logo.png"
              alt="logo"
              style="width: 100px; margin-bottom: 20px"
            />
            <h1>{{ appName }}</h1>
            <small>{{ appDescription }}</small>
          </div>
          <!-- <div class="icon">
          <i class="fa fa-lock"></i>
        </div> -->
        </div>
        <div class="login-content">
          <form @submit.prevent="login" method="POST" class="margin-bottom-0">
            <div class="form-group m-b-20">
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Username"
                id="username"
                v-model="username"
                required
              />
            </div>
            <div class="form-group m-b-20">
              <input
                type="password"
                class="form-control form-control-lg"
                placeholder="Password"
                id="password"
                v-model="password"
                required
              />
            </div>
            <div class="checkbox checkbox-css m-b-20">
              <input type="checkbox" id="remember_me_checkbox" value="" />
              <label for="remember_me_checkbox"> Remember Me </label>
            </div>
            <b-alert v-if="alert == 'error'" variant="danger" show fade>
              <strong>Login Gagal!</strong>
              <br />
              <span>{{ message }}</span>
            </b-alert>
            <b-alert v-if="alert == 'success'" variant="success" show fade>
              <strong>Login Success!</strong>
              <br />
              <span>{{ message }}</span>
            </b-alert>
            <div class="login-buttons">
              <button type="submit" class="btn btn-orange btn-block btn-lg">
                <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                Login
              </button>
            </div>
            <div class="m-t-20">
              <p class="text-center text-grey-darker">
                &copy; 2024 DLH KOTA PALU
              </p>
              <p class="text-center text-grey-darker" style="margin-top: -10px">
                {{ appName }} v{{ appVersion }}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageOptions from "../config/PageOptions.vue";
import axios from "axios";
import packageJson from "@/../package.json";

export default {
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      message: "",
      alert: "",
      appName: packageJson.title,
      appDescription: packageJson.description,
      appVersion: packageJson.version,
    };
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    login() {
      this.loading = true;
      this.alert = "";
      const formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);

      axios
        .post("/v1/auth/login/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.status == true) {
            this.loading = false;
            this.alert = "success";
            this.message = "Selamat Datang " + response.data.nama;

            setTimeout(() => {
              const sipData = {
                nama: response.data.nama,
                email: response.data.email,
                foto: response.data.foto,
                level: response.data.level,
                hak_akses: response.data.hak_akses,
                token: response.data.token,
              };
              localStorage.setItem("sipData", JSON.stringify(sipData));
              if (response.data.level === "admin") {
                this.$router.push("/dashboard");
              } else if (response.data.level === "user") {
                this.$router.push("/dashboard");
              } else if (response.data.level === "penagih") {
                this.$router.push("/data-pelanggan");
              } else {
                this.$router.push("/dashboard");
              }
            }, 2000);
          } else {
            this.loading = false;
            this.alert = "error";
            this.message = response.data.message;
            setTimeout(() => {
              this.alert = "";
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>