<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">Import Data</h3>
        </div>
        <div class="panel-body p-5">
          <div class="row mb-2">
            <div class="col-md-2">
              <select
                v-model="form.district"
                name="district"
                id="district"
                v-on:change="fetchVillages($event)"
                class="form-control"
                :class="{
                  'is-invalid': formValidate.district,
                }"
              >
                <option value="">Kecamatan</option>
                <option
                  v-for="districts in districtList"
                  :value="districts.id_kec"
                  :key="districts.id_kec"
                >
                  {{ districts.nama_kecamatan }}
                </option>
              </select>
            </div>

            <div class="col-md-2">
              <select
                v-model="form.village"
                name="village"
                id="village"
                class="form-control"
                :class="{
                  'is-invalid': formValidate.village,
                }"
              >
                <option value="">Kelurahan</option>
                <option
                  v-for="villages in villageList"
                  :value="villages.id_kel"
                  :key="villages.id_kel"
                >
                  {{ villages.nama_kelurahan }}
                </option>
              </select>
            </div>
            <div class="col-md-4">
              <input
                class="form-control"
                type="file"
                @change="handleFileUpload"
              />
            </div>
            <div class="col-md-2">
              <button class="btn btn-info btn-block" @click="importData">
                Read Data
              </button>
            </div>
            <div class="col-md-2">
              <button class="btn btn-success btn-block" @click="postData">
                Send Data
              </button>
            </div>
          </div>
          <vue-good-table
            :search-options="{
              enabled: false,
              placeholder: 'Pencarian',
            }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: true,
              mode: 'records',
              perPage: 20,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: true,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div>
                  <!-- <button
                    class="btn btn-danger btn-circle btn-icon"
                    v-on:click="confirmDelete(props.row.customer_category_id)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button> -->
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";
import ExcelJS from "exceljs";

export default {
  name: "DataList",
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "Nama",
          field: "customer_name",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Alamat",
          field: "customer_address",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Kecamatan",
          field: "district",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Kelurahan",
          field: "village",
          sortable: false,
          width: "150px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "RW",
          field: "hamlet",
          sortable: false,
          width: "50px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "RT",
          field: "neighbourhood",
          sortable: false,
          width: "50px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      form: {
        customer_id: "",
        nik: "",
        npwrd: "",
        customer_name: "",
        customer_address: "",
        district: "",
        village: "",
        hamlet: "",
        neighbourhood: "",
        customer_categories: "",
        created_by: "",
        created_at: "",
      },
      tableInfo: "Empty data",
      isLoading: false,
      formValidate: [],
      districtList: [],
      villageList: [],
      file: null,
    };
  },
  mounted() {
    this.fetchDistricts();
  },
  methods: {
    handleFileUpload(e) {
      this.file = e.target.files[0];
    },
    importData() {
      if (this.file) {
        const workbook = new ExcelJS.Workbook();
        const reader = new FileReader();

        reader.onload = async (e) => {
          const arrayBuffer = e.target.result;
          const buffer = Buffer.from(arrayBuffer);
          await workbook.xlsx.load(buffer);

          const worksheet = workbook.getWorksheet(1);
          const importedData = [];
          worksheet.eachRow((row) => {
            // console.log(row.values[1]);
            if (row.number > 1) {
              let hamlet =
                row.values[6] !== null &&
                row.values[6] !== undefined &&
                row.values[6] !== ""
                  ? row.values[6].toString().padStart(3, "0")
                  : "000";
              let neighbourhood =
                row.values[7] !== null &&
                row.values[7] !== undefined &&
                row.values[7] !== ""
                  ? row.values[7].toString().padStart(3, "0")
                  : "000";
              let customer_address =
                row.values[4] !== null &&
                row.values[4] !== undefined &&
                row.values[4] !== ""
                  ? row.values[4].toString()
                  : "-";
              if (row.values[3] && row.values[3] != "-") {
                const rowData = {
                  customer_name: row.values[3],
                  customer_address: customer_address,
                  district: this.form.district,
                  village: this.form.village,
                  hamlet: hamlet,
                  neighbourhood: neighbourhood,
                };
                importedData.push(rowData);
              }
            }
          });

          this.rows = importedData;
        };

        reader.readAsArrayBuffer(this.file);
      }
    },
    postData() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("data_import", JSON.stringify(this.rows));
      axios
        .post("/v1/old_customers/import/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.isLoading = false;
            this.formValidate = response.data.message;
          } else {
            this.isLoading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            this.modalVisible = false;
            this.conditionMet = true;
            setTimeout(() => {
              Swal.close();
              this.$router.go(-1);
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchDistricts() {
      axios
        .get("/v1/daerah/kecamatan/7271", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.districtList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchVillages(event) {
      axios
        .get("/v1/daerah/kelurahan/" + event.target.value, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.villageList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>