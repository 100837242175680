<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">List Transaksi</h3>
          <span>Rp. {{ this.currencyFormat(this.totalAmount) }}</span>
        </div>
        <div class="panel-body p-5">
          <div class="row">
            <div class="col-10">
              <input
                v-model="filter.date"
                @change="fetchData"
                type="date"
                class="form-control mb-2"
              />
            </div>
            <div class="col-2">
              <button
                type="button"
                v-on:click="downloadExcel()"
                to="/data-transaksi/create"
                class="btn btn-success btn-block"
              >
                <i class="fa fa-file-excel d-md-none"></i>
                <span class="d-none d-md-block">Export Excel</span>
              </button>
            </div>
          </div>
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :search-options="{
              enabled: true,
              trigger: 'keyup',
              placeholder: 'Pencarian',
            }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-default mr-2"
                >
                  <i
                    class="fa fa-sync"
                    :class="{ 'fa-spin': this.isLoading }"
                  ></i>
                </button>
                <!-- <router-link
                  to="/data-transaksi/bulk"
                  class="btn btn-warning mr-2"
                >
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Tagihan Khusus</span>
                </router-link> -->
                <router-link
                  to="/data-transaksi/create"
                  class="btn btn-primary mr-2"
                >
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Transaksi Baru</span>
                </router-link>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'status'">
                <button
                  v-on:click="
                    confirmTransaction(
                      props.row.transaction_id,
                      props.row.transaction_status
                    )
                  "
                  class="btn btn-sm btn-circle btn-icon btn-success m-r-5"
                  :class="{
                    'btn-grey': props.row.transaction_status == '0',
                  }"
                  :disabled="
                    props.row.transaction_status == '1' && userLevel != 'admin'
                  "
                >
                  <i
                    class="fa fa-check"
                    :class="{
                      'fa-exclamation': props.row.transaction_status == '0',
                    }"
                  ></i>
                </button>
              </span>
              <span v-if="props.column.field == 'action'">
                <div>
                  <b-dropdown
                    variant="none"
                    :no-caret="true"
                    toggle-class="btn btn-circle btn-icon btn-info  m-r-5"
                  >
                    <template slot="button-content">
                      <i class="fa fa-print"></i>
                    </template>
                    <b-dropdown-item
                      v-on:click="printSKRD(props.row.transaction_id)"
                      >Print SKRD</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="props.row.transaction_status == '1'"
                      v-on:click="printSSRD(props.row.transaction_id)"
                      >Print SSRD</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="props.row.transaction_status == '1'"
                      v-on:click="printBoth(props.row.transaction_id)"
                      >Print SKRD & SSRD</b-dropdown-item
                    >
                  </b-dropdown>
                  <router-link
                    :to="'/data-transaksi/detail/' + props.row.transaction_id"
                    class="btn btn-circle btn-icon btn-success m-r-5"
                  >
                    <i class="fa fa-search"></i>
                  </router-link>
                  <button
                    v-if="userLevel == 'admin'"
                    class="btn btn-circle btn-icon btn-danger"
                    v-on:click="confirmDelete(props.row.transaction_id)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";
import moment from "moment";
const ExcelJS = require("exceljs");

export default {
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "Tanggal",
          field: "transaction_date",
          sortable: false,
          formatFn: this.indonesianFormat,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "NPWRD",
          field: "npwrd",
          sortable: false,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Nama",
          field: "customer_name",
          sortable: false,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-wrap",
        },
        {
          label: "Alamat",
          field: "customer_address",
          sortable: false,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-wrap",
        },
        {
          label: "Jenis Kegiatan",
          field: "category_name",
          sortable: false,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Satuan",
          field: "cost",
          sortable: false,
          formatFn: this.currencyFormat,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Jumlah Bayar",
          field: "payment_amount",
          sortable: false,
          formatFn: this.currencyFormat,
          thClass: "text-center text-wrap valign-middle",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Periode",
          field: "additional_details",
          sortable: false,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Pembayaran",
          field: "payment_method",
          sortable: false,
          thClass: "text-center text-wrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
          thClass: "text-center text-wrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Diinput Oleh",
          field: "created_name",
          sortable: false,
          thClass: "text-center text-wrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap valign-middle",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      filter: {
        date: "",
      },
      searchTerm: "",
      timeoutSearch: null,
      loadDPT: false,
      totalAmount: 0,
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    onSearch(params) {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(() => {
        this.searchTerm = params.searchTerm;
        this.fetchData();
      }, 500);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchData();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.fetchData();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.fetchData();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.fetchData();
    },

    fetchData() {
      this.isLoading = true;
      axios
        .get("/v1/transactions/", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            date: this.filter.date,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.total;
          this.totalAmount = response.data.penerimaan;
          if (this.totalRecords <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/transactions/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    indonesianFormat(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    currencyFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    printSKRD(params) {
      this.$router.push("data-transaksi/skrd/" + params);
      // window.alert(params);
    },
    printSSRD(params) {
      this.$router.push("data-transaksi/ssrd/" + params);
      // window.alert(params);
    },
    printBoth(params) {
      this.$router.push("data-transaksi/print/" + params);
      // window.alert(params);
    },
    downloadExcel() {
      axios
        .get("/v1/transactions/export", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            date: this.filter.date,
          },
        })
        .then((response) => {
          let data = response.data.data;

          let dataArray = data.map((item, index) => ({
            index: index + 1,
            transaction_date:
              this.indonesianFormat(item.transaction_date).toUpperCase() || " ",
            npwrd: parseInt(item.npwrd) || " ",
            customer_name: item.customer_name.toUpperCase() || " ",
            customer_address: item.customer_address.toUpperCase() || " ",
            district_name: item.district_name.toUpperCase() || " ",
            village_name: item.village_name.toUpperCase() || " ",
            hamlet: item.hamlet.toString() || " ",
            neighbourhood: item.neighbourhood.toString() || " ",
            category_name: item.category_name.toUpperCase() || " ",
            cost: parseInt(item.cost) || " ",
            additional_details: item.additional_details.toUpperCase() || " ",
            payment_method: item.payment_method.toUpperCase() || " ",
            payment_amount: parseInt(item.payment_amount) || " ",
            created_name: item.created_name.toUpperCase() || " ",
            transaction_code: item.transaction_code || " ",
          }));

          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Data Transaksi");

          const columnWidths = [
            { header: "NO.", key: "index", width: 5 },
            { header: "TANGGAL", key: "transaction_date", width: 20 },
            { header: "NPWRD", key: "npwrd", width: 15 },
            { header: "NAMA", key: "customer_name", width: 30 },
            {
              header: "ALAMAT",
              key: "customer_address",
              width: 40,
            },
            { header: "KECAMATAN", key: "district_name", width: 20 },
            { header: "KELURAHAN", key: "village_name", width: 20 },
            { header: "RW", key: "hamlet", width: 7 },
            { header: "RT", key: "neighbourhood", width: 7 },
            {
              header: "JENIS KEGIATAN",
              key: "category_name",
              width: 35,
            },
            { header: "SATUAN", key: "cost", width: 15 },
            { header: "PERIODE", key: "additional_details", width: 27 },
            { header: "METODE PEMBAYARAN", key: "payment_method", width: 15 },
            { header: "JUMLAH BAYAR", key: "payment_amount", width: 18 },
            { header: "NAMA PENGINPUT", key: "created_name", width: 18 },
            {
              header: "KODE TRANSAKSI TRANSFER",
              key: "transaction_code",
              width: 25,
            },
          ];

          worksheet.columns = columnWidths;

          dataArray.forEach((item) => {
            worksheet.addRow(item);
          });

          const totalPaymentAmount = dataArray.reduce(
            (total, item) => total + (parseInt(item.payment_amount) || 0),
            0
          );

          worksheet.addRow({
            payment_amount: totalPaymentAmount,
          });

          const columnCenter = [
            "npwrd",
            "transaction_date",
            "payment_method",
            "category_name",
            "additional_details",
            "district_name",
            "village_name",
            "hamlet",
            "neighbourhood",
            "transaction_code",
            "created_name",
          ];
          columnCenter.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
          });

          const columnRight = ["index", "cost", "payment_amount"];
          columnRight.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "right",
              vertical: "middle",
              wrapText: true,
            };
          });

          const columnLeft = ["customer_name", "customer_address"];
          columnLeft.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "left",
              vertical: "middle",
              wrapText: true,
            };
          });

          const headerRow = worksheet.getRow(1);
          headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
          });

          const borderStyle = {
            style: "thin",
            color: { argb: "000000" },
          };

          worksheet.eachRow((row) => {
            row.eachCell((cell) => {
              cell.border = {
                top: borderStyle,
                left: borderStyle,
                bottom: borderStyle,
                right: borderStyle,
              };
            });
          });

          const lastRow = worksheet.lastRow;
          lastRow.eachCell({ includeEmpty: true }, (cell) => {
            cell.font = { bold: true };
            cell.alignment = { horizontal: "right", vertical: "middle" };
            cell.border = {
              top: borderStyle,
              left: borderStyle,
              bottom: borderStyle,
              right: borderStyle,
            };
          });
          worksheet.mergeCells(`A${lastRow.number}:M${lastRow.number}`);

          worksheet.getCell(`L${lastRow.number}`).value = "JUMLAH TOTAL BAYAR";
          worksheet.getCell(`L${lastRow.number}`).alignment = {
            horizontal: "right",
            vertical: "middle",
          };

          return workbook.xlsx.writeBuffer();
        })
        .then((excelBuffer) => {
          this.saveAsExcelFile(excelBuffer, "data-transaksi.xlsx");
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    saveAsExcelFile(buffer, filename) {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      }
    },

    confirmTransaction(id, status) {
      let titleAlert = "Konfirmasi Transaksi ?";
      let textAlert = "Harap Memastikan Data Sudah Sesuai";
      let confirmButton = "Konfirmasi";
      if (status == 1) {
        titleAlert = "Batalkan Status Transaksi ?";
        textAlert = "Tindakan ini akan membatalkan status transaksi";
        confirmButton = "Batalkan Konfirmasi";
      }
      Swal.fire({
        title: titleAlert,
        text: textAlert,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: confirmButton,
      }).then((result) => {
        if (result.value) {
          try {
            axios
              .get("/v1/transactions/confirm/", {
                headers: {
                  token: this.userToken,
                },
                params: {
                  id: id,
                  status: status,
                },
              })
              .then((response) => {
                // console.log(response.status);
                if (response.status === 201) {
                  this.fetchData();
                  // this.isChecked = true;
                }
              })
              .catch((error) => {
                console.log(error.message);
                this.error = error.message;
              });
          } catch (err) {
            console.error(err);
          }
        }
      });
    },
  },
};
</script>