var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('div',{staticClass:"panel-body p-5"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-8"},[_c('input',{staticClass:"form-control",attrs:{"type":"file"},on:{"change":_vm.handleFileUpload}})]),_c('div',{staticClass:"col-md-2"},[_c('button',{staticClass:"btn btn-info btn-block",on:{"click":_vm.importData}},[_vm._v(" Read Data ")])]),_c('div',{staticClass:"col-md-2"},[_c('button',{staticClass:"btn btn-success btn-block",on:{"click":_vm.postData}},[_vm._v(" Send Data ")])])]),_c('vue-good-table',{attrs:{"search-options":{
            enabled: true,
            placeholder: 'Pencarian',
          },"pagination-options":{
            enabled: true,
            perPageDropdownEnabled: true,
            mode: 'records',
            perPage: 20,
            perPageDropdown: [10, 20, 30, 40, 50, 100],
            dropdownAllowAll: true,
            position: 'bottom',
            rowsPerPageLabel: '',
            nextLabel: '',
            prevLabel: '',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All',
          },"rows":_vm.rows,"columns":_vm.columns},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('div')]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.tableInfo)+" ")])])],1),_vm._m(1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-dark text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Rekening Koran")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-footer"},[_c('a',{staticClass:"btn btn-default",attrs:{"href":"javascript:window.history.go(-1);"}},[_vm._v("Kembali")])])
}]

export { render, staticRenderFns }