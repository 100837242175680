var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('div',{staticClass:"panel-body p-5"},[_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"search-options":{
            enabled: true,
            trigger: 'keyup',
            placeholder: 'Pencarian',
          },"pagination-options":{
            enabled: true,
            perPageDropdownEnabled: true,
            perPageDropdown: [10, 20, 30, 40, 50, 100],
            dropdownAllowAll: false,
            position: 'bottom',
            rowsPerPageLabel: '',
            nextLabel: '',
            prevLabel: '',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: '',
          },"rows":_vm.rows,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.onSearch,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('div',[_c('router-link',{staticClass:"btn btn-circle btn-icon btn-success m-l-5 m-r-5",attrs:{"to":'/data-pelanggan/detail/' + props.row.customer_id}},[_c('i',{staticClass:"fa fa-search"})]),_c('router-link',{staticClass:"btn btn-circle btn-icon btn-warning m-r-5",attrs:{"to":'/data-pelanggan/update/' + props.row.customer_id}},[_c('i',{staticClass:"fa fa-pencil-alt"})]),_c('button',{staticClass:"btn btn-circle btn-icon btn-danger",on:{"click":function($event){return _vm.confirmDelete(props.row.customer_id)}}},[_c('i',{staticClass:"fa fa-trash-alt"})])],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.tableInfo)+" ")]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-default mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.fetchData()}}},[_c('i',{staticClass:"fa fa-sync",class:{ 'fa-spin': this.isLoading }})]),_c('router-link',{staticClass:"btn btn-primary mr-2",attrs:{"to":"/data-pelanggan/create"}},[_c('i',{staticClass:"fa fa-plus d-md-none"}),_c('span',{staticClass:"d-none d-md-block"},[_vm._v("Pelanggan Baru")])])],1)])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-dark text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("List Pelanggan")])])
}]

export { render, staticRenderFns }